import React, { useState, useEffect, useRef } from 'react';
import '../styles/searchScreen.css'

import search_icon from '../assets/svgFiles/SearchIcon.svg'
import pdf_icon from '../assets/svgFiles/ExternalLinkIcon.svg'

import booksData from '../constants/books.json'

const SearchScreen = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchBy, setSearchBy] = useState('title'); // 'title' or 'author'
    const [filteredBooks, setFilteredBooks] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedAuthor, setSelectedAuthor] = useState('');
    const [isSearchInShahmukhi, setIsSearchInShahmukhi] = useState(false);
    const suggestionsRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);





    // use effects

    // old useeffect one lang
    // useEffect(() => {
    //     // Only filter suggestions if the searchQuery does not match the selected suggestion
    //     if (searchQuery !== '' && (searchBy === 'title' ? selectedTitle : selectedAuthor) !== searchQuery) {
    //         let filteredSuggestions = booksData.filter(book => {
    //             if (searchBy === 'title') {
    //                 return book.title.toLowerCase().includes(searchQuery.toLowerCase());
    //             } else if (searchBy === 'author') {
    //                 return book.author.toLowerCase().includes(searchQuery.toLowerCase());
    //             }
    //         });

    //         if (searchBy === 'author') {
    //             // Create a unique list of authors
    //             const authors = filteredSuggestions.map(book => book.author);
    //             filteredSuggestions = authors.filter((author, index) => authors.indexOf(author) === index).map(author => ({ author }));
    //         }

    //         // to show results in alphabetical order.
    //         filteredSuggestions.sort((a, b) => {
    //             const keyA = searchBy === 'title' ? a.title : a.author;
    //             const keyB = searchBy === 'title' ? b.title : b.author;
    //             return keyA.localeCompare(keyB);
    //         });


    //         setSuggestions(filteredSuggestions);
    //     } else {
    //         // Clear suggestions if the searchQuery matches the selected suggestion
    //         setSuggestions([]);
    //     }
    // }, [searchQuery, searchBy, selectedTitle, selectedAuthor]);


    // new useeffect two languages
    useEffect(() => {
        if (searchQuery !== '' && (searchBy === 'title' ? selectedTitle : selectedAuthor) !== searchQuery) {
            let filteredSuggestions = booksData.filter(book => {
                if (searchBy === 'title') {
                    return book.title.toLowerCase().includes(searchQuery.toLowerCase()) || book.title_in_shahmukhi.includes(searchQuery);
                } else if (searchBy === 'author') {
                    return book.author.toLowerCase().includes(searchQuery.toLowerCase()) || book.authorName_in_shahmukhi.includes(searchQuery);
                }
            });

            if (searchBy === 'author') {
                // Create a unique list of authors (both in English and Shahmukhi)
                const authors = filteredSuggestions.map(book => ({ author: book.author, authorName_in_shahmukhi: book.authorName_in_shahmukhi }));
                filteredSuggestions = authors.filter((author, index, self) =>
                    index === self.findIndex(t => t.author === author.author && t.authorName_in_shahmukhi === author.authorName_in_shahmukhi)
                );
            }

            // Sort the suggestions alphabetically (based on the language of the input)
            filteredSuggestions.sort((a, b) => {
                const keyA = searchBy === 'title' ? (a.title_in_shahmukhi.includes(searchQuery) ? a.title_in_shahmukhi : a.title) : (a.authorName_in_shahmukhi.includes(searchQuery) ? a.authorName_in_shahmukhi : a.author);
                const keyB = searchBy === 'title' ? (b.title_in_shahmukhi.includes(searchQuery) ? b.title_in_shahmukhi : b.title) : (b.authorName_in_shahmukhi.includes(searchQuery) ? b.authorName_in_shahmukhi : b.author);
                return keyA.localeCompare(keyB);
            });

            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [searchQuery, searchBy, selectedTitle, selectedAuthor]);

    // new useeffect ends


    // dropdown scrollable when pressing up and down button
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                setHighlightedIndex(prevIndex => {
                    const newIndex = event.key === 'ArrowDown' ? prevIndex + 1 : prevIndex - 1;
                    return Math.min(Math.max(newIndex, 0), suggestions.length - 1);
                });
                const suggestionsElement = suggestionsRef.current;
                if (suggestionsElement) {
                    const suggestionHeight = suggestionsElement.firstChild.offsetHeight;
                    if (event.key === 'ArrowDown') {
                        suggestionsElement.scrollTop += suggestionHeight;
                    }
                    else {
                        suggestionsElement.scrollTop -= suggestionHeight;
                    }
                }
            }
        };


        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);






    // use effects end

    // functions

    const handleSearchClick = (suggestion) => {
        console.log(suggestion);

        // in this function we are setting the search results like if user search in english results 
        // will be shown in english and if user enters shahmukhi then results will be shown in shahmukhi. 
        if (searchBy === 'title') {
            if (suggestion.title_in_shahmukhi.includes(searchQuery)) {
                setSelectedTitle(suggestion.title_in_shahmukhi);
                setSearchQuery(suggestion.title_in_shahmukhi);
            } else {
                setSelectedTitle(suggestion.title);
                setSearchQuery(suggestion.title);
            }
        } else {
            if (suggestion.authorName_in_shahmukhi.includes(searchQuery)) {
                setSelectedAuthor(suggestion.authorName_in_shahmukhi);
                setSearchQuery(suggestion.authorName_in_shahmukhi);
            } else {
                setSelectedAuthor(suggestion.author);
                setSearchQuery(suggestion.author);
            }
        }
        setSuggestions([]);
    };


    const handleSearch = () => {
        setSuggestions([]);

        if (searchQuery !== '') {
            const results = booksData.filter(book => {
                if (searchBy === 'title') {
                    return book.title.toLowerCase().includes(searchQuery.toLowerCase()) || book.title_in_shahmukhi.includes(searchQuery);
                } else {
                    return book.author.toLowerCase().includes(searchQuery.toLowerCase()) || book.authorName_in_shahmukhi.includes(searchQuery);
                }
            });

            // Determine if the search is in Shahmukhi after filtering the results
            const isShahmukhi = results.some(book => book.title_in_shahmukhi.includes(searchQuery) || book.authorName_in_shahmukhi.includes(searchQuery));
            setIsSearchInShahmukhi(isShahmukhi);

            setFilteredBooks(results);
        } else {
            setFilteredBooks([]);
            setIsSearchInShahmukhi(false);
        }

        // const results = booksData.filter(book => {
        //     if (searchBy === 'title') {
        //         return book.title.toLowerCase().includes(searchQuery.toLowerCase());
        //     } else {
        //         return book.author.toLowerCase().includes(searchQuery.toLowerCase());
        //     }
        // });
        // setFilteredBooks(results);

    };


    // openpdf currently

    // functions ends

    // front end starts
    return (
        <div> 

            {/* header */}
            <header className='header'>
                <span className='headerText'>Punjabiboli</span>
            </header>

            {/* other content */}
            <div className='searchContainer'>
                <h4 className='heading'>A Public Domain Work</h4>
                <p className='description'>
                    The public domain consists of all the creative work to which no exclusive intellectual property rights apply.
                    No one holds the exclusive rights, anyone can legally use or reference this works without permission.
                </p>

                {/* input */}

                <div className="search-input-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder={searchBy == 'title' ? "Enter Book Title" : "Enter Book Author"}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSearch()} // Search when the user presses Enter
                    />
                    <img
                        className='search-icon'
                        src={search_icon}
                        alt="search"
                        style={{ cursor: 'pointer' }}
                        onClick={handleSearch} // Search when the user clicks the search icon
                    />
                    {suggestions.length > 0 && (
                        <div className="suggestions-dropdown" ref={suggestionsRef}>
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={suggestion.id}

                                    className={`suggestion ${index === highlightedIndex ? 'highlighted' : ''}`}
                                    onClick={() => handleSearchClick(suggestion)}
                                >

                                    {searchBy === 'title' ? (suggestion.title_in_shahmukhi.includes(searchQuery) ? suggestion.title_in_shahmukhi : suggestion.title) : (suggestion.authorName_in_shahmukhi.includes(searchQuery) ? suggestion.authorName_in_shahmukhi : suggestion.author)}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* input ends */}

                <div className='radioButtonsContainer'>
                    <label className='radioLabel'>
                        <input
                            type='radio'
                            name='searchBy'
                            value='title'
                            checked={searchBy === 'title'}
                            onChange={() => setSearchBy('title')}
                        />
                        Book Title
                    </label>
                    <label className='radioLabel'>
                        <input
                            type='radio'
                            name='searchBy'
                            value='author'
                            checked={searchBy === 'author'}
                            onChange={() => setSearchBy('author')}
                        />
                        Book Author
                    </label>
                </div>





                {/* new  */}
                <div className="search-results">
                    <div className="search-result-header">
                        <div className="search-result-title">Title</div>
                        <div className="search-result-author-header">Author</div>
                        <div className="pdf-button-header"></div>
                    </div>

                    {
                        filteredBooks.length > 0 ? (
                            filteredBooks
                                // .sort((a, b) => a.title.localeCompare(b.title)) // This will sort the books by title in alphabetical order
                                .map(book => (
                                    <div key={book.id} className="search-result-row">

                                        <div className="search-result-title">
                                            {isSearchInShahmukhi ? book.title_in_shahmukhi : book.title}
                                        </div>
                                        <div className="search-result-author">
                                            {isSearchInShahmukhi ? book.authorName_in_shahmukhi : book.author}
                                        </div>


                                        <a href={book.pdfLink} target="_blank" rel="noopener noreferrer">
                                            <div className='pdf-click' style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={pdf_icon}
                                                    alt="pdf"
                                                    style={{ cursor: 'pointer', }}
                                                />
                                                <span className="pdf-text">PDF</span>
                                            </div>
                                        </a>
                                    </div>
                                ))
                        ) : (
                            <div className='no-results'>
                                No results found.
                            </div>
                        )
                    }
                </div>


                {/* results ends */}
            </div>

            {/* other content ends */}

            {/* footer */}
            <footer className='footer'>
                <span className='footerText'>The public domain consists of all the creative work to which no exclusive intellectual property rights apply.{<br />}
                    No one holds the exclusive rights, anyone can legally use or reference this works without permission.
                    <br />
                    <span className='lastText'> Copyright © 2024 Punjabiboli</span>

                </span>
            </footer>
        </div>
    );
};

export default SearchScreen;
