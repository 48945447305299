import React from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

import './App.css';
import SearchScreen from './Screens/SearchScreen';

// Set the title in your main or where your app is initialized
// document.title = "Punjabiboli";

function App() {
  return (
   <Router>
      <Routes>
        <Route path='/' Component={SearchScreen}/>
      </Routes>
   </Router>
  );
}

export default App;
